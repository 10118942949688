import { IconMap } from '@/constants/iconMap'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import classNames from 'classnames'
import { Suspense, lazy, useMemo } from 'react'

export type IconProps = {
  name: IconNamesEnum
  className?: string
  onClick?(): void
}

const Icon = ({ name, className, onClick }: IconProps) => {
  const IconComponent = useMemo(() => lazy(IconMap[name]), [name])
  return (
    <Suspense fallback={<div className={className ? classNames(className) : 'h-24 w-24'} />}>
      <IconComponent className={className ? classNames(className) : 'h-24 w-24'} onClick={() => onClick?.()} />
    </Suspense>
  )
}

export default Icon
